import React, { useState } from 'react';
import { Text } from 'spoton-lib';
import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import { LoadOptions, Response } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';

import Styles from './Location.module.scss';
import { useAppDispatch, useAppSelector } from 'api/store';
import { StoreCandidates } from '../../MerchantOnboarding.types';
import * as merchantOnboardingSlice from '../../MerchantOnboarding.slice';
import { LocationError } from './LocationError';
import { PaginateDropdown, ReusableModal } from 'features/common';

interface ILocation {
    isSyncLocationModalOpen: boolean;
    setIsSyncLocationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSyncLocationConfirm: (selectedLocation: StoreCandidates) => void;
    setIsSessionExpired: React.Dispatch<React.SetStateAction<boolean>>;
}

export type Option = {
    label: string;
    value: StoreCandidates;
    isDisabled: boolean;
};

type Additional = {
    page: number;
};

export function Location(props: ILocation) {
    const {
        isSyncLocationModalOpen,
        setIsSyncLocationModalOpen,
        onSyncLocationConfirm,
        setIsSessionExpired,
    } = props;

    const dispatch = useAppDispatch();
    const {
        businessLocationId,
        exchangeCode,
        locationErrorMessage,
        providerStoreName,
        providerAddress,
    } = useAppSelector((state) => state.merchantOnboarding);
    const [selectedLocation, setSelectedLocation] = useState<Option | null>(
        null,
    );

    const onConfirm = () => {
        if (selectedLocation) {
            onSyncLocationConfirm(selectedLocation.value);
            setSelectedLocation(null);
        }
    };

    const loadOptions: LoadOptions<
        any,
        GroupBase<any>,
        Additional | undefined
    > = async (
        searchQuery,
        loadedOptions,
        { page }: any,
    ): Promise<Response<any, GroupBase<any>, Additional | undefined>> => {
        if (businessLocationId && exchangeCode) {
            const pageSize = 50;
            const storeListRes = await dispatch(
                merchantOnboardingSlice.getStoreList({
                    businessLocationId,
                    exchange_code: exchangeCode,
                    pageIndex: page,
                    pageSize,
                }),
            );
            if (storeListRes && isFulfilled(storeListRes)) {
                const storesList =
                    storeListRes.payload.data.store_candidates.map((store) => ({
                        value: store,
                        label: store.name,
                        isDisabled: !store.is_eligible_to_onboard,
                    }));
                return {
                    options: storesList,
                    hasMore:
                        Math.ceil(
                            storeListRes.payload.data.total_count / pageSize,
                        ) > page,
                    additional: {
                        page: page + 1,
                    },
                };
            }

            if (storeListRes && isRejectedWithValue(storeListRes)) {
                if (storeListRes.payload?.errors.statusCode === 440) {
                    setIsSessionExpired(true);
                }
            }
        }
        return {
            options: [],
            hasMore: false,
        };
    };

    return (
        <ReusableModal
            isOpen={isSyncLocationModalOpen}
            title="Choose DoorDash location"
            onCancel={() => {
                setIsSyncLocationModalOpen(false);
                setSelectedLocation(null);
            }}
            onConfirm={onConfirm}
            isConfirmBtnDisabled={
                locationErrorMessage || !selectedLocation ? true : false
            }
            showFooter={locationErrorMessage === undefined}
            gap={providerAddress || providerStoreName ? '1rem' : '1.5rem'}
            contentClassName={
                locationErrorMessage ? Styles.Location_errorContent : ''
            }
        >
            <Text type="sub2" style={{ fontWeight: '400' }}>
                Pick the location you want to connect to your SpotOn location.
            </Text>
            {(providerAddress || providerStoreName) && (
                <span
                    data-testid="provider-info"
                    className={Styles.Location_providerInfo}
                >
                    <Text
                        style={{ fontWeight: '500', letterSpacing: '0.2px' }}
                        color="#0F1221"
                    >
                        SpotOn Location:
                    </Text>
                    {providerStoreName && (
                        <Text
                            type="sub2"
                            style={{ fontWeight: '400' }}
                            data-testid="provider-store-name"
                        >
                            {providerStoreName}
                        </Text>
                    )}
                    {providerAddress && (
                        <Text
                            color="#505A79"
                            data-testid="provider-store-address"
                        >{`${providerAddress.street_address}, ${providerAddress.city}, ${providerAddress.state} ${providerAddress.zip}, ${providerAddress.country}`}</Text>
                    )}
                </span>
            )}
            <span
                className={Styles.Location_doorDashLocation}
                data-testid="doordash-location-info"
            >
                <Text
                    style={{ fontWeight: '500', letterSpacing: '0.2px' }}
                    color="#0F1221"
                >
                    DoorDash location:
                </Text>
                <PaginateDropdown
                    onChange={(value) => setSelectedLocation(value as Option)}
                    value={selectedLocation}
                    placeholder={
                        locationErrorMessage
                            ? 'No locations found'
                            : 'Select a store'
                    }
                    styles={{
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 100,
                        }),
                        control: (base) => ({
                            ...base,
                            borderRadius: '0.5rem',
                            borderColor: locationErrorMessage
                                ? '#D54041 !important'
                                : '#DBDFE5 !important',
                            border: locationErrorMessage
                                ? '2px solid #D54041 !important'
                                : '1px solid #DBDFE5 !important',
                        }),
                    }}
                    className={Styles.Location_dropdown}
                    loadOptions={loadOptions}
                />
                {locationErrorMessage && (
                    <LocationError
                        locationErrorMessage={locationErrorMessage}
                    />
                )}
            </span>
            {!locationErrorMessage && (
                <Text
                    type="sub2"
                    style={{ fontWeight: '400', color: '#505A79' }}
                >
                    Syncing these locations will overwrite existing integrations
                    with either the SpotOn location or the selected DoorDash
                    location.
                </Text>
            )}
        </ReusableModal>
    );
}
