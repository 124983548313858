import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isFulfilled } from '@reduxjs/toolkit';

import styles from './MerchantOnboarding.module.scss';
import { useAppDispatch, useAppSelector } from 'api/store';
import * as merchantOnboardingSlice from './MerchantOnboarding.slice';
import {
    MenuStatus,
    StoreCandidates,
    OnboardingStatus as OnboardingStatusEnum,
} from './MerchantOnboarding.types';
import { getConfigVar } from 'features/common';
import {
    ActivationModal,
    LandingPage,
    Location,
    OnboardingStatus,
    SessionExpiredModal,
    SuccessModal,
} from './components';

export function MerchantOnboarding() {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const [isSyncLocationModalOpen, setIsSyncLocationModalOpen] =
        useState<boolean>(false);
    const [isOnboardingStatusModalOpen, setIsOnboardingStatusModalOpen] =
        useState<boolean>(false);
    const [isActivationModalOpen, setIsActivationModalOpen] =
        useState<boolean>(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] =
        useState<boolean>(false);
    const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
    const {
        businessLocationId,
        stepsList,
        onboardingId,
        onboardingStatus,
        exchangeCode,
        isError,
        providerStoreName,
        isActivationRequested,
    } = useAppSelector((state) => state.merchantOnboarding);
    const onSyncLocationConfirm = async (selectedLocation: StoreCandidates) => {
        if (businessLocationId) {
            const res = await dispatch(
                merchantOnboardingSlice.initializeOnboarding({
                    doordash_store_uuid: selectedLocation.doordash_store_uuid,
                    location_id: businessLocationId,
                    exchange_code: localStorage.getItem('exchangeCode'),
                    provider_store_name: providerStoreName
                        ? providerStoreName
                        : '',
                    store_name: selectedLocation.name,
                    provider_store_address: selectedLocation.address,
                    business_name: selectedLocation.business_name,
                    business_uuid: selectedLocation.business_uuid,
                }),
            );

            if (res && isFulfilled(res)) {
                if (res.payload.status === 'success') {
                    const getStoreIntegrationStatusRes = await dispatch(
                        merchantOnboardingSlice.getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                    setIsSyncLocationModalOpen(false);
                    if (
                        getStoreIntegrationStatusRes &&
                        isFulfilled(getStoreIntegrationStatusRes)
                    ) {
                        if (
                            getStoreIntegrationStatusRes.payload.data
                                .onboarding_status === 'MENU_REQUESTED'
                        ) {
                            setIsOnboardingStatusModalOpen(true);
                        }
                    }
                }
            }
        }
    };

    const onClickTryAgainBtn = async () => {
        if (businessLocationId) {
            const menuRequestRes = await dispatch(
                merchantOnboardingSlice.menuRequest({ businessLocationId }),
            );
            if (menuRequestRes && isFulfilled(menuRequestRes)) {
                if (menuRequestRes.payload.status === 'success') {
                    dispatch(merchantOnboardingSlice.setIsError(false));
                    dispatch(
                        merchantOnboardingSlice.setOnboardingStatus(
                            OnboardingStatusEnum.MENU_REQUESTED,
                        ),
                    );
                    if (!isOnboardingStatusModalOpen) {
                        setIsOnboardingStatusModalOpen(true);
                    }
                }
            }
        }
    };

    const onClickActivationBtn = async () => {
        if (onboardingId) {
            const activateIntegrationRes = await dispatch(
                merchantOnboardingSlice.activateIntegration({
                    onboarding_id: onboardingId,
                }),
            );
            if (activateIntegrationRes && isFulfilled(activateIntegrationRes)) {
                if (activateIntegrationRes.payload.status === 'success') {
                    if (businessLocationId) {
                        const getStoreIntegrationStatusRes = await dispatch(
                            merchantOnboardingSlice.getStoreIntegrationStatus({
                                businessLocationId,
                            }),
                        );
                        if (isActivationModalOpen) {
                            setIsActivationModalOpen(false);
                        }
                        if (
                            getStoreIntegrationStatusRes &&
                            isFulfilled(getStoreIntegrationStatusRes)
                        ) {
                            if (
                                getStoreIntegrationStatusRes.payload.data
                                    .onboarding_status ===
                                'INTEGRATION_ACTIVATED'
                            ) {
                                setIsSuccessModalOpen(true);
                            }
                        }
                    }
                }
            }
        }
    };

    const getMenuStatus = async () => {
        if (onboardingId && businessLocationId) {
            const menuStatusRes = await dispatch(
                merchantOnboardingSlice.getMenuStatus({ onboardingId }),
            );
            if (menuStatusRes && isFulfilled(menuStatusRes)) {
                if (menuStatusRes.payload.status === 'success') {
                    if (
                        menuStatusRes.payload.data.status ===
                            MenuStatus.SUCCESS ||
                        menuStatusRes.payload.data.status === MenuStatus.FAILED
                    ) {
                        dispatch(
                            merchantOnboardingSlice.getStoreIntegrationStatus({
                                businessLocationId,
                            }),
                        );
                    }
                }
            }
        }
    };

    useEffect(() => {
        const menuStatus = stepsList.find((step) => step.key === 'menuStatus');
        if (
            !menuStatus?.isProcessCompleted &&
            onboardingId &&
            isOnboardingStatusModalOpen &&
            !isError
        ) {
            getMenuStatus();
            const intervalId = setInterval(
                () => {
                    getMenuStatus();
                },
                parseInt(getConfigVar('REACT_APP_POLLING_INTERVAL_TIME')),
            );

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [stepsList, onboardingId, isOnboardingStatusModalOpen, isError]);

    const getStoreIntegrationStatus = async () => {
        if (businessLocationId && onboardingId) {
            const getStoreIntegrationStatusRes = await dispatch(
                merchantOnboardingSlice.getStoreIntegrationStatus({
                    businessLocationId,
                }),
            );
            if (
                getStoreIntegrationStatusRes &&
                isFulfilled(getStoreIntegrationStatusRes)
            ) {
                const status =
                    getStoreIntegrationStatusRes.payload.data.onboarding_status;
                if (
                    (status === OnboardingStatusEnum.MENU_BLOCK ||
                        status === OnboardingStatusEnum.MENU_QUALIFIED) &&
                    isActivationRequested === false
                ) {
                    const menuStatusRes = await dispatch(
                        merchantOnboardingSlice.getMenuStatus({ onboardingId }),
                    );
                    if (menuStatusRes && isFulfilled(menuStatusRes)) {
                        if (menuStatusRes.payload.status === 'success') {
                            setIsOnboardingStatusModalOpen(true);
                        }
                    }
                }
                if (status === OnboardingStatusEnum.INTEGRATION_ACTIVATED) {
                    setIsSuccessModalOpen(true);
                }
            }
        }
    };

    useEffect(() => {
        if (
            !isOnboardingStatusModalOpen &&
            (onboardingStatus === OnboardingStatusEnum.MENU_REQUESTED ||
                isActivationRequested)
        ) {
            const intervalId = setInterval(
                () => {
                    getStoreIntegrationStatus();
                },
                parseInt(getConfigVar('REACT_APP_POLLING_INTERVAL_TIME')),
            );

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [isOnboardingStatusModalOpen, onboardingStatus, isActivationRequested]);

    useEffect(() => {
        const exchangeCodeFromUrl = searchParams.get('code');
        if (exchangeCodeFromUrl) {
            localStorage.setItem('exchangeCode', exchangeCodeFromUrl);
        }
    }, [searchParams]);

    useEffect(() => {
        if (exchangeCode && !onboardingId) {
            setIsSyncLocationModalOpen(true);
        }
    }, [exchangeCode, onboardingId]);

    useEffect(() => {
        if (!isSyncLocationModalOpen) {
            dispatch(
                merchantOnboardingSlice.setLocationErrorMessage(undefined),
            );
        }
    }, [isSyncLocationModalOpen, exchangeCode]);

    return (
        <div className={styles.MerchantOnboarding}>
            <LandingPage
                setIsSyncLocationModalOpen={setIsSyncLocationModalOpen}
                onClickTryAgainBtn={onClickTryAgainBtn}
                onClickActivationBtn={onClickActivationBtn}
            />
            <Location
                isSyncLocationModalOpen={isSyncLocationModalOpen}
                setIsSyncLocationModalOpen={setIsSyncLocationModalOpen}
                onSyncLocationConfirm={onSyncLocationConfirm}
                setIsSessionExpired={setIsSessionExpired}
            />
            <OnboardingStatus
                isOnboardingStatusModalOpen={isOnboardingStatusModalOpen}
                setIsOnboardingStatusModalOpen={setIsOnboardingStatusModalOpen}
                setIsActivationModalOpen={setIsActivationModalOpen}
                onClickTryAgainBtn={onClickTryAgainBtn}
            />
            <ActivationModal
                isActivationModalOpen={isActivationModalOpen}
                setIsActivationModalOpen={setIsActivationModalOpen}
                onClickActivationBtn={onClickActivationBtn}
            />
            <SuccessModal
                isSuccessModalOpen={isSuccessModalOpen}
                setIsSuccessModalOpen={setIsSuccessModalOpen}
            />
            <SessionExpiredModal
                isSessionExpired={isSessionExpired}
                setIsSessionExpired={setIsSessionExpired}
            />
        </div>
    );
}
