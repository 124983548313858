import React from 'react';
import {
    Button,
    LoadingIndicator,
    Modal,
    ModalFooter,
    ModalHeader,
    Text,
} from 'spoton-lib';

import styles from './ReusableModal.module.scss';

interface IReusableModal {
    isOpen: boolean;
    title: string | JSX.Element;
    showFooter?: boolean;
    showCancelBtn?: boolean;
    showLoadingIndicator?: boolean;
    children: React.ReactNode;
    onCancel: () => void;
    onConfirm?: () => void;
    cancelText?: string;
    confirmText?: string;
    confirmButtonColor?: string;
    isConfirmBtnDisabled?: boolean;
    gap?: number | string;
    headerClassName?: string;
    contentClassName?: string;
}

export function ReusableModal(props: IReusableModal) {
    const {
        children,
        isOpen,
        title,
        showFooter = true,
        showCancelBtn = true,
        showLoadingIndicator = false,
        cancelText = 'Cancel',
        confirmText = 'Continue',
        confirmButtonColor = '#2D71E8',
        onCancel,
        onConfirm,
        isConfirmBtnDisabled = false,
        gap,
        headerClassName,
        contentClassName = '',
    } = props;

    const Title = () => {
        if (typeof title === 'string') {
            return <Text type="h4">{title}</Text>;
        }
        return title;
    };

    const headerComponent = (
        <ModalHeader
            className={`${styles.ReusableModal_header} ${headerClassName}`}
            content={<Title />}
            onRequestClose={onCancel}
        />
    );

    const footerComponent = (
        <ModalFooter className={styles.ReusableModal_footer}>
            {showCancelBtn && (
                <Button
                    variant="secondary"
                    onClick={onCancel}
                    className={styles.ReusableModal_cancelButton}
                    data-testid="modal-cancel-button"
                >
                    {cancelText}
                </Button>
            )}
            {onConfirm && (
                <Button
                    onClick={onConfirm}
                    className={styles.ReusableModal_confirmButton}
                    style={{
                        backgroundColor: confirmButtonColor,
                        border: `1px solid ${confirmButtonColor}`,
                    }}
                    disabled={isConfirmBtnDisabled}
                    data-testid="modal-confirm-button"
                >
                    {confirmText}
                </Button>
            )}
        </ModalFooter>
    );

    return (
        <>
            <Modal
                shouldCloseOnOverlayClick={false}
                testId="modal"
                ariaHideApp={false}
                className={styles.ReusableModal}
                isOpen={isOpen}
                onRequestClose={onCancel}
                headerComponent={headerComponent}
                footerComponent={showFooter ? footerComponent : undefined}
            >
                {showLoadingIndicator && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <LoadingIndicator
                            data-testid="loading-indicator"
                            size="sm"
                            greyAnimation
                        />
                    </div>
                )}
                <div
                    className={`${styles.ReusableModal_content} ${contentClassName} ${showLoadingIndicator ? styles.ReusableModal_content___loading : ''}`}
                    style={{ gap }}
                >
                    {children}
                </div>
            </Modal>
        </>
    );
}
