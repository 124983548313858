import {
    ActivateIntegrationRequest,
    ActivateIntegrationResponse,
    GetStoreListRequest,
    GetStoreListResponse,
    InitializeOnboardingRequest,
    InitializeOnboardingResponse,
    MenuStatusRequest,
    MenuStatusResponse,
    MenusRequest,
    MenusResponse,
    RestartProcessRequest,
    RestartProcessResponse,
    SignupUrlRequest,
    SignupUrlResponse,
    StoreIntegrationStatusRequest,
    StoreIntegrationStatusResponse,
    StorePauseRequest,
    StorePauseResponse,
} from 'features/merchant_onboarding/MerchantOnboarding.types';
import { AxiosAPI } from 'utils/axios';
import { REQUEST_METHOD } from 'utils/constants';
import { ROUTES } from 'utils/endpoints';

export const getSignupUrl = async (request: SignupUrlRequest) => {
    return await AxiosAPI.request<SignupUrlResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.SIGNUP_URL}/${request.businessLocationId}`,
    });
};

export const getStoreList = async (request: GetStoreListRequest) => {
    return await AxiosAPI.request<GetStoreListResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.STORE_LIST}/${request.businessLocationId}/${request.exchange_code}/${request.pageIndex}/${request.pageSize}`,
    });
};

export const activateIntegration = async (
    request: ActivateIntegrationRequest,
) => {
    return await AxiosAPI.request<ActivateIntegrationResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.ACTIVATE_INTEGRATION}/${request.onboarding_id}`,
    });
};

export const initializeOnboarding = async (
    request: InitializeOnboardingRequest,
) => {
    return await AxiosAPI.request<InitializeOnboardingResponse>({
        method: REQUEST_METHOD.POST,
        url: ROUTES.INITIALIZE_ONBOARDING,
        data: request,
    });
};

export const getMenuStatus = async (request: MenuStatusRequest) => {
    return await AxiosAPI.request<MenuStatusResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.MENU_STATUS}/${request.onboardingId}`,
    });
};

export const getStoreIntegrationStatus = async (
    request: StoreIntegrationStatusRequest,
) => {
    return await AxiosAPI.request<StoreIntegrationStatusResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.STORE_INTEGRATION_STATUS}/${request.businessLocationId}`,
    });
};

export const menuRequest = async (request: MenusRequest) => {
    return await AxiosAPI.request<MenusResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.MENU_REQUEST}/${request.businessLocationId}`,
    });
};

export const storePause = async (request: StorePauseRequest) => {
    const { businessLocationId, ...data } = request;

    return await AxiosAPI.request<StorePauseResponse>({
        method: REQUEST_METHOD.PUT,
        url: `${ROUTES.STORE_PAUSE}/${businessLocationId}`,
        data,
    });
};

export const restartProcess = async (request: RestartProcessRequest) => {
    return await AxiosAPI.request<RestartProcessResponse>({
        method: REQUEST_METHOD.PUT,
        url: `${ROUTES.RESTART_PROCESS}/${request.businessLocationId}`,
    });
};
