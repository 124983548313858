export const ROUTES = {
    SIGNUP_URL: '/signup-url',
    GET_API_KEY: '/auth/get-api-key',
    GET_ACCESS_TOKEN: '/auth/get-access-token',
    GET_ACCESS_TOKEN_RELOAD: '/auth/regenerate-access-token',
    STORE_LIST: '/store-list',
    INITIALIZE_ONBOARDING: '/onboarding',
    STORE_INTEGRATION_STATUS: '/store-integrate',
    MENU_STATUS: '/menu/status',
    MENU_REQUEST: '/menu-request',
    ACTIVATE_INTEGRATION: '/activate',
    STORE_PAUSE: '/store/pause',
    RESTART_PROCESS: '/store/onboard/restart',
};
