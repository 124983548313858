import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showToast } from 'spoton-lib';

import {
    ActivateIntegrationRequest,
    GetStoreListRequest,
    GetStoreListResponse,
    IMerchantOnboardingSliceState,
    InitializeOnboardingRequest,
    MenuStatusRequest,
    MenusRequest,
    OnboardingStatus,
    RestartProcessRequest,
    SignupUrlRequest,
    StepsStatus,
    StoreIntegrationStatusRequest,
    StorePauseRequest,
} from './MerchantOnboarding.types';
import * as API from './MerchantOnboarding.api';
import { steps } from './MerchantOnboarding.utility';
import { ErrorResult, SuccessResult } from 'utils/axios.types';

export const initialState: IMerchantOnboardingSliceState = {
    loading: false,
    isInitialPageLoading: true,
    onboardingId: localStorage.getItem('onboardingId'),
    storeName: null,
    providerStoreName: null,
    stepsList: steps,
    storeList: [],
    locationErrorMessage: undefined,
    exchangeCode: null,
    isError: false,
    isOrderActive: false,
    isActivationRequested: false,
    exclusionCodeDetails: '',
};

export const merchantOnboardingSlice = createSlice({
    name: 'merchantOnboarding',
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setIsInitialPageLoading: (
            state,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.isInitialPageLoading = payload;
        },
        setLocationErrorMessage: (
            state,
            {
                payload,
            }: PayloadAction<
                IMerchantOnboardingSliceState['locationErrorMessage']
            >,
        ) => {
            state.locationErrorMessage = payload;
        },
        setExchangeCode: (state, { payload }: PayloadAction<string | null>) => {
            state.exchangeCode = payload;
        },
        setIsError: (state, { payload }: PayloadAction<boolean>) => {
            state.isError = payload;
            state.menuStatus = undefined;
        },
        setbusinessLocationId: (
            state,
            { payload }: PayloadAction<string | undefined>,
        ) => {
            state.businessLocationId = payload;
        },
        setOnboardingStatus: (
            state,
            { payload }: PayloadAction<OnboardingStatus | undefined>,
        ) => {
            state.onboardingStatus = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getStoreIntegrationStatus.fulfilled,
            (state, { payload }) => {
                state.onboardingId = payload.data.onboarding_id;
                state.exchangeCode = localStorage.getItem('exchangeCode');
                state.isError = payload.data.is_error;
                state.stepsList = state.stepsList.map((step) => {
                    return {
                        ...step,
                        isProcessCompleted:
                            payload.data[step.key as keyof StepsStatus],
                    };
                });
                state.storeName = payload.data.store_name;
                state.providerStoreName = payload.data.provider_store_name;
                state.providerAddress = payload.data.provider_address;
                state.isOrderActive = payload.data.is_order_active;
                state.onboardingStatus = payload.data.onboarding_status;
                state.exclusionCodeDetails = payload.data.details;
                state.exclusionCode = payload.data.exclusion_code;
                state.businessUUID = payload.data.business_uuid;
                state.isActivationRequested =
                    payload.data.is_activation_requested;
            },
        );
        builder.addCase(getMenuStatus.fulfilled, (state, { payload }) => {
            state.menuStatus = payload.data;
        });
        builder.addCase(restartProcess.fulfilled, (state, { payload }) => {
            if (payload.data.success) {
                state.isError = false;
                state.menuStatus = undefined;
                state.onboardingStatus = undefined;
            }
        });
    },
});

export const {
    setLoading,
    setIsInitialPageLoading,
    setLocationErrorMessage,
    setExchangeCode,
    setIsError,
    setOnboardingStatus,
    setbusinessLocationId,
} = merchantOnboardingSlice.actions;

export const getSignupUrl = createAsyncThunk(
    'merchantOnboarding/getSignupUrl',
    async (request: SignupUrlRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.getSignupUrl(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const getStoreList = createAsyncThunk<
    SuccessResult<GetStoreListResponse>,
    GetStoreListRequest,
    { rejectValue: ErrorResult }
>(
    'merchantOnboarding/getStoreList',
    async (request: GetStoreListRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.getStoreList(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            dispatch(
                setLocationErrorMessage({
                    statusCode: response.errors.statusCode,
                    message: response.errors.error,
                }),
            );
            return rejectWithValue(response as ErrorResult);
        }
    },
);

export const activateIntegration = createAsyncThunk(
    'merchantOnboarding/activateIntegration',
    async (
        request: ActivateIntegrationRequest,
        { rejectWithValue, dispatch },
    ) => {
        dispatch(setLoading(true));
        const response = await API.activateIntegration(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const initializeOnboarding = createAsyncThunk(
    'merchantOnboarding/initializeOnboarding',
    async (
        request: InitializeOnboardingRequest,
        { rejectWithValue, dispatch },
    ) => {
        dispatch(setLoading(true));
        const response = await API.initializeOnboarding(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const getMenuStatus = createAsyncThunk(
    'merchantOnboarding/getMenuStatus',
    async (request: MenuStatusRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.getMenuStatus(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const getStoreIntegrationStatus = createAsyncThunk(
    'merchantOnboarding/getStoreIntegrationStatus',
    async (
        request: StoreIntegrationStatusRequest,
        { rejectWithValue, dispatch },
    ) => {
        const response = await API.getStoreIntegrationStatus(request);
        dispatch(setIsInitialPageLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const menuRequest = createAsyncThunk(
    'merchantOnboarding/menuRequest',
    async (request: MenusRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.menuRequest(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const storePause = createAsyncThunk(
    'merchantOnboarding/storePause',
    async (request: StorePauseRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.storePause(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            showToast({
                content: response.data.message,
                containerId: 'toast-container',
                position: 'top-center',
            });
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const restartProcess = createAsyncThunk(
    'merchantOnboarding/restartProcess',
    async (request: RestartProcessRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.restartProcess(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);
