import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    colors,
    Container,
    Hyperlink,
    Icon,
    LoadingOverlay,
    Row,
    Switch,
    Text,
    ToggleTip,
} from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';

import styles from './LandingPage.module.scss';
import { getConfigVar } from 'features/common';
import { useAppDispatch, useAppSelector } from 'api/store';
import {
    getSignupUrl,
    getStoreIntegrationStatus,
    restartProcess,
    storePause,
} from '../../MerchantOnboarding.slice';
import { description } from '../../MerchantOnboarding.utility';
import { PageTitle } from '../page_title';
import {
    MenuFastFoodIcon,
    ProgressIndicatorIcon,
    WarningSolidIcon,
} from 'features/merchant_onboarding/assets';
import { OnboardingStatus } from 'features/merchant_onboarding/MerchantOnboarding.types';
import { URL } from 'utils/constants';

interface ILandingPage {
    setIsSyncLocationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickTryAgainBtn: () => void;
    onClickActivationBtn: () => void;
}

export function LandingPage({
    setIsSyncLocationModalOpen,
    onClickTryAgainBtn,
    onClickActivationBtn,
}: ILandingPage) {
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {
        isInitialPageLoading,
        businessLocationId,
        isError,
        exchangeCode,
        stepsList,
        storeName,
        providerStoreName,
        isOrderActive,
        loading,
        onboardingStatus,
        exclusionCodeDetails,
        exclusionCode,
        businessUUID,
        isActivationRequested,
    } = useAppSelector((state) => state.merchantOnboarding);

    const onClickSignUpLink = async () => {
        if (businessLocationId) {
            const res = await dispatch(getSignupUrl({ businessLocationId }));

            if (res && isFulfilled(res)) {
                if (res.payload.status === 'success') {
                    window.location.href = res.payload.data.signup_url;
                }
            }
        }
    };
    const onClickGetStartedBtn = () => {
        if (exchangeCode) {
            setIsSyncLocationModalOpen(true);
        } else {
            window.location.href = getConfigVar(
                'REACT_APP_DOORDASH_SIGNIN_URL',
            );
        }
    };

    const onClickRestartBtn = async () => {
        if (businessLocationId) {
            const restartProcessRes = await dispatch(
                restartProcess({ businessLocationId }),
            );
            if (restartProcessRes && isFulfilled(restartProcessRes)) {
                if (restartProcessRes.payload.data.success) {
                    await dispatch(
                        getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                }
            }
        }
    };

    const onClickGoToDoordash = () => {
        const url = businessUUID
            ? `${URL.DOORDASH_URL}?business_uuid=${businessUUID}`
            : URL.DOORDASH_URL;
        window.open(url);
    };

    const onClickContactSupportLink = () => {
        window.open(URL.DOORDASH_CONTACT_SUPPORT_URL);
    };

    const onChangeStorePauseStatus = async () => {
        if (businessLocationId) {
            const response = await dispatch(
                storePause({
                    businessLocationId,
                    is_active: !isOrderActive,
                    reason: isOrderActive
                        ? 'store_self_disabled_in_their_POS_portal'
                        : undefined,
                }),
            );
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    await dispatch(
                        getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (businessLocationId) {
            dispatch(
                getStoreIntegrationStatus({
                    businessLocationId,
                }),
            );
        }
    }, [businessLocationId]);

    if (isInitialPageLoading) {
        return <LoadingOverlay data-testid="page-loading" />;
    }

    return (
        <>
            <Container fluid className={styles.LandingPage}>
                <Row className={styles.LandingPage_row}>
                    {stepsList.every((step) => step.isProcessCompleted) ? (
                        <Col
                            data-testid="landing-page-steps-completed-container"
                            className={styles.LandingPage_processCompleted}
                        >
                            <Text
                                type="h4"
                                as="header"
                                data-testid="landing-page-header-completed"
                            >
                                DoorDash Integration
                            </Text>
                            <Card className={styles.LandingPage_settingCard}>
                                <Row>
                                    <Col>
                                        <Text
                                            type="sub1"
                                            isBold
                                            data-testid="landing-page-sub-header"
                                        >
                                            Accept orders from DoorDash
                                        </Text>
                                    </Col>
                                    <Col xs="content">
                                        {loading ? (
                                            <Button
                                                style={{
                                                    minHeight: 'fit-content',
                                                }}
                                                variant="tertiary"
                                                isLoading={loading}
                                            />
                                        ) : (
                                            <Switch
                                                checked={isOrderActive}
                                                onChange={
                                                    onChangeStorePauseStatus
                                                }
                                                data-testid="store-active-deactive-btn"
                                            />
                                        )}
                                    </Col>
                                </Row>
                                {isOrderActive ? (
                                    <Text
                                        type="sub2"
                                        color={colors.base70}
                                        data-testid="landing-page-info"
                                    >
                                        You are receiving DoorDash orders in
                                        SpotOn now and any menu changes will be
                                        published automatically.
                                    </Text>
                                ) : (
                                    <ul
                                        style={{
                                            paddingInlineStart: '1.5625rem',
                                            color: colors.base70,
                                            margin: '0px',
                                        }}
                                    >
                                        <li>
                                            Your DoorDash orders are paused.
                                        </li>
                                        <li>
                                            Your DoorDash store will be
                                            temporarily hidden.
                                        </li>
                                        <li>You can resume at any time.</li>
                                    </ul>
                                )}
                                <Button
                                    data-testid="go-to-doorDash-btn"
                                    className={styles.GotoDoorDashButton}
                                    variant="tertiary"
                                    onClick={onClickGoToDoordash}
                                >
                                    <Icon size={24} name="ExternalLinkIcon" />
                                    <span style={{ marginLeft: '0.5rem' }}>
                                        Go to DoorDash
                                    </span>
                                </Button>
                            </Card>
                        </Col>
                    ) : (
                        <Col data-testid="landing-page-steps-container">
                            <PageTitle />
                            <div className={styles.Steps}>
                                {stepsList.map((step, index) => (
                                    <Card
                                        key={index}
                                        className={styles.Steps_card}
                                        data-testid="landing-page-steps-card"
                                    >
                                        <Container fluid>
                                            <Row>
                                                <Col
                                                    width={'2rem'}
                                                    style={{
                                                        paddingLeft: '8px',
                                                    }}
                                                >
                                                    {step.isProcessCompleted ? (
                                                        <ProgressIndicatorIcon />
                                                    ) : (
                                                        <Text
                                                            className={`${
                                                                styles.Steps_cardNumber
                                                            } ${
                                                                ((index === 0 &&
                                                                    onboardingStatus ===
                                                                        OnboardingStatus.ABANDONED) ||
                                                                    (index >
                                                                        0 &&
                                                                        stepsList[
                                                                            index -
                                                                                1
                                                                        ]
                                                                            .isProcessCompleted &&
                                                                        !step.isProcessCompleted &&
                                                                        onboardingStatus !==
                                                                            OnboardingStatus.ABANDONED)) &&
                                                                styles.Steps_cardNumber___completed
                                                            }`}
                                                            data-testid="landing-page-steps-card-number"
                                                        >
                                                            {index + 1}
                                                            {((index === 0 &&
                                                                onboardingStatus ===
                                                                    OnboardingStatus.ABANDONED) ||
                                                                (index > 0 &&
                                                                    stepsList[
                                                                        index -
                                                                            1
                                                                    ]
                                                                        .isProcessCompleted &&
                                                                    !step.isProcessCompleted &&
                                                                    onboardingStatus !==
                                                                        OnboardingStatus.ABANDONED)) &&
                                                                isError && (
                                                                    <WarningSolidIcon
                                                                        className={
                                                                            styles.Steps_card___warning
                                                                        }
                                                                    />
                                                                )}
                                                        </Text>
                                                    )}
                                                </Col>
                                                <Col>
                                                    <Text
                                                        type="h6"
                                                        data-testid="landing-page-steps-card-title"
                                                    >
                                                        {step.title}
                                                    </Text>
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            gap: '0.25rem',
                                                        }}
                                                    >
                                                        <Text
                                                            data-testid="landing-page-steps-card-description"
                                                            color="#505A79"
                                                        >
                                                            {description({
                                                                isPending:
                                                                    index > 0 &&
                                                                    stepsList[
                                                                        index -
                                                                            1
                                                                    ]
                                                                        .isProcessCompleted &&
                                                                    !step.isProcessCompleted,
                                                                isError,
                                                                isProcessCompleted:
                                                                    step.isProcessCompleted,
                                                                key: step.key,
                                                                storeName,
                                                                providerStoreName,
                                                                onboardingStatus,
                                                                exclusionCodeDetails,
                                                                exclusionCode,
                                                                isActivationRequested,
                                                            })}
                                                        </Text>
                                                        {step.key ===
                                                            'locationStatus' &&
                                                            stepsList[0]
                                                                .isProcessCompleted && (
                                                                <ToggleTip
                                                                    isOpen={
                                                                        isTooltipOpen
                                                                    }
                                                                    onClose={() =>
                                                                        setIsTooltipOpen(
                                                                            false,
                                                                        )
                                                                    }
                                                                    title={
                                                                        <Text
                                                                            type="bold"
                                                                            fontSize={
                                                                                14
                                                                            }
                                                                        >
                                                                            Change
                                                                            Location
                                                                        </Text>
                                                                    }
                                                                    trigger={
                                                                        <Button
                                                                            data-testid="toggleBtn"
                                                                            variant="ghost"
                                                                            onClick={() =>
                                                                                setIsTooltipOpen(
                                                                                    !isTooltipOpen,
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                color="#7D89A3"
                                                                                name="InfoCircleIcon"
                                                                                size={
                                                                                    18
                                                                                }
                                                                            />
                                                                        </Button>
                                                                    }
                                                                    variant="topLeft"
                                                                    className={
                                                                        styles.LandingPage_tooltip
                                                                    }
                                                                >
                                                                    To select a
                                                                    different
                                                                    location
                                                                    please
                                                                    contact
                                                                    DoorDash
                                                                    support at
                                                                    <Hyperlink
                                                                        size={
                                                                            12
                                                                        }
                                                                        href="tel:855 222 8111"
                                                                    >
                                                                        855 222
                                                                        8111
                                                                    </Hyperlink>
                                                                    or through
                                                                    their
                                                                    <Hyperlink
                                                                        size={
                                                                            12
                                                                        }
                                                                        onClick={
                                                                            onClickContactSupportLink
                                                                        }
                                                                    >
                                                                        merchant
                                                                        support
                                                                        portal
                                                                    </Hyperlink>
                                                                </ToggleTip>
                                                            )}
                                                    </span>
                                                </Col>
                                                {step.key ===
                                                    'locationStatus' &&
                                                    onboardingStatus ===
                                                        OnboardingStatus.ABANDONED &&
                                                    isError && (
                                                        <Col width={'10rem'}>
                                                            <Button
                                                                className={
                                                                    styles.LandingPage_processButton
                                                                }
                                                                onClick={
                                                                    onClickRestartBtn
                                                                }
                                                                data-testid="restart-ssio-flow-btn"
                                                            >
                                                                {
                                                                    step.buttonText
                                                                }
                                                            </Button>
                                                        </Col>
                                                    )}
                                                {step.key ===
                                                    'activateStatus' &&
                                                    stepsList[index - 1]
                                                        .isProcessCompleted &&
                                                    !step.isProcessCompleted &&
                                                    !isActivationRequested && (
                                                        <Col width={'10rem'}>
                                                            <Button
                                                                data-testid="activate-btn"
                                                                className={
                                                                    styles.LandingPage_processButton
                                                                }
                                                                onClick={
                                                                    onClickActivationBtn
                                                                }
                                                            >
                                                                {
                                                                    step.buttonText
                                                                }
                                                            </Button>
                                                        </Col>
                                                    )}
                                                {step.key === 'menuStatus' &&
                                                    stepsList[index - 1]
                                                        .isProcessCompleted &&
                                                    !step.isProcessCompleted &&
                                                    onboardingStatus !==
                                                        OnboardingStatus.ABANDONED &&
                                                    isError && (
                                                        <Col width={'10rem'}>
                                                            <Button
                                                                data-testid="menu-request-again-btn"
                                                                className={
                                                                    styles.LandingPage_processButton
                                                                }
                                                                onClick={
                                                                    onClickTryAgainBtn
                                                                }
                                                            >
                                                                {
                                                                    step.buttonText
                                                                }
                                                            </Button>
                                                        </Col>
                                                    )}
                                            </Row>
                                        </Container>
                                    </Card>
                                ))}
                            </div>
                            {!stepsList.some(
                                (step) => step.isProcessCompleted,
                            ) &&
                                !isError && (
                                    <>
                                        {!exchangeCode && (
                                            <Text
                                                className={
                                                    styles.LandingPage_note
                                                }
                                                data-testid="landing-page-note"
                                            >
                                                If you&apos;re not a DoorDash
                                                customer,
                                                <Hyperlink
                                                    onClick={onClickSignUpLink}
                                                    className={
                                                        styles.LandingPage_signupLink
                                                    }
                                                    data-testid="sign-up-link"
                                                >
                                                    sign up here.
                                                </Hyperlink>
                                            </Text>
                                        )}
                                        <Button
                                            className={
                                                styles.LandingPage_getStartedButton
                                            }
                                            data-testid="get-started-btn"
                                            onClick={onClickGetStartedBtn}
                                        >
                                            Get started
                                        </Button>
                                    </>
                                )}
                        </Col>
                    )}
                    <Col
                        xs="content"
                        className={styles.LandingPage_iconContainer}
                        data-testid="menu-fast-food-icon-container"
                    >
                        <MenuFastFoodIcon className={styles.LandingPage_icon} />
                    </Col>
                </Row>
            </Container>
            {stepsList.every((step) => step.isProcessCompleted) && (
                <Text
                    type="sub2"
                    className={styles.ContactInfo}
                    data-testid="contact-info"
                >
                    To select a different location or permanently deactivate
                    integration please contact DoorDash support at
                    <Hyperlink
                        href="tel:855 222 8111"
                        className={styles.ContactInfo_link}
                        data-testid="contact-number"
                    >
                        855 222 8111
                    </Hyperlink>
                    or through their
                    <Hyperlink
                        onClick={onClickContactSupportLink}
                        className={styles.ContactInfo_link}
                        data-testid="contact-support-link"
                    >
                        merchant support portal
                    </Hyperlink>
                </Text>
            )}
        </>
    );
}
