export const HTTP_STATUS_CODES = {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    PRECONDITION_FAILED: 412,
};

export const REQUEST_METHOD = {
    POST: 'POST',
    GET: 'GET',
    DELETE: 'DELETE',
    PUT: 'PUT',
};

export const URL = {
    DOORDASH_CONTACT_SUPPORT_URL:
        'https://help.doordash.com/merchants/s/merchant-support?language=en_US',
    DOORDASH_URL: 'https://www.doordash.com/merchant/summary',
    DOORDASH_LEARN_MORE: '#',
};

export const ROOT_ELEMENT_ID = 'single-spa-application:@spoton/doordash';
