import React from 'react';
import { Hyperlink, Icon, Text } from 'spoton-lib';

import { ReusableModal } from 'features/common';
import { URL } from 'utils/constants';

interface ISuccessModal {
    isSuccessModalOpen: boolean;
    setIsSuccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SuccessModal(props: ISuccessModal) {
    const { isSuccessModalOpen, setIsSuccessModalOpen } = props;

    return (
        <ReusableModal
            isOpen={isSuccessModalOpen}
            title="DoorDash is Live on SpotOn!"
            onCancel={() => setIsSuccessModalOpen(false)}
            confirmText="Done"
            showCancelBtn={false}
            onConfirm={() => setIsSuccessModalOpen(false)}
        >
            <div>
                <Text fontSize={16}>
                    Congratulations! Your DoorDash integration is now live.
                </Text>
                <Hyperlink
                    size={16}
                    href={URL.DOORDASH_LEARN_MORE}
                    target="_blank"
                    color="#1C5DCD"
                >
                    <Icon size={18} name="ExternalLinkIcon" /> Learn more
                </Hyperlink>
            </div>
        </ReusableModal>
    );
}
