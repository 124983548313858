import React from 'react';

export function WarningSolidIcon({
    className,
}: {
    className?: string;
}): JSX.Element {
    return (
        <svg
            width={16}
            height={15}
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 -0.000488281C3.85786 -0.000488281 0.5 3.35738 0.5 7.49951C0.5 11.6416 3.85786 14.9995 8 14.9995C12.1421 14.9995 15.5 11.6416 15.5 7.49951C15.5 3.35738 12.1421 -0.000488281 8 -0.000488281ZM8 3.74951C8.41421 3.74951 8.75 4.0853 8.75 4.49951V7.49951C8.75 7.91373 8.41421 8.24951 8 8.24951C7.58579 8.24951 7.25 7.91373 7.25 7.49951V4.49951C7.25 4.0853 7.58579 3.74951 8 3.74951ZM8 9.74951C8.41421 9.74951 8.75 10.0853 8.75 10.4995V10.512C8.75 10.9262 8.41421 11.262 8 11.262C7.58579 11.262 7.25 10.9262 7.25 10.512V10.4995C7.25 10.0853 7.58579 9.74951 8 9.74951Z"
                fill="#DA0B09"
            />
        </svg>
    );
}
