import React from 'react';
import { Hyperlink, Icon, Text } from 'spoton-lib';

import { useAppSelector } from 'api/store';
import { MenuModalTitle } from '../../MerchantOnboarding.utility';
import { ReusableModal } from 'features/common';
import { URL } from 'utils/constants';
import { MenuStatus } from 'features/merchant_onboarding/MerchantOnboarding.types';
import styles from './OnboardingStatus.module.scss';

interface IOnboardingStatus {
    isOnboardingStatusModalOpen: boolean;
    setIsOnboardingStatusModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsActivationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickTryAgainBtn: () => void;
}

export function OnboardingStatus(props: IOnboardingStatus) {
    const {
        isOnboardingStatusModalOpen,
        setIsOnboardingStatusModalOpen,
        setIsActivationModalOpen,
        onClickTryAgainBtn,
    } = props;
    const { menuStatus, onboardingStatus } = useAppSelector(
        (state) => state.merchantOnboarding,
    );

    const content = (status: MenuStatus | undefined) => {
        switch (status) {
            case MenuStatus.SUCCESS:
                return (
                    <>
                        <Text fontSize={16}>
                            Check it out before you continue to make sure
                            everything looks ok. Please note: photos in your
                            Express Menu will sync as well, but this process can
                            take up to 72 hours.
                        </Text>
                        {menuStatus && menuStatus.menus.length > 0 && (
                            <Hyperlink
                                size={16}
                                className={
                                    styles.OnboardingStatus_doorDashMenuLink
                                }
                                href={menuStatus.menus[0].menu_preview_link}
                                target="_blank"
                                color="#1C5DCD"
                            >
                                <Icon size={18} name="ExternalLinkIcon" /> View
                                DoorDash Menu
                            </Hyperlink>
                        )}
                    </>
                );
            case MenuStatus.FAILED:
                return (
                    <Text
                        style={{
                            fontSize: '1rem',
                            color: '#0F1221',
                        }}
                    >
                        We were unable to sync your menu. Please try again
                        later. If the error persists{' '}
                        <Hyperlink
                            style={{
                                fontSize: '1rem',
                                color: '#0F1221',
                                fontWeight: '500',
                            }}
                            href={URL.DOORDASH_CONTACT_SUPPORT_URL}
                            target="_blank"
                        >
                            contact support
                        </Hyperlink>
                    </Text>
                );
            default:
                return (
                    <Text fontSize="1rem">
                        This may take a few minutes. You can close this message
                        and return anytime to complete the setup.
                    </Text>
                );
        }
    };

    const onConfirm = (status: MenuStatus | undefined) => {
        if (status === MenuStatus.SUCCESS) {
            setIsOnboardingStatusModalOpen(false);
            setIsActivationModalOpen(true);
        } else {
            onClickTryAgainBtn();
        }
    };

    return (
        <ReusableModal
            isOpen={isOnboardingStatusModalOpen}
            title={MenuModalTitle(menuStatus?.status)}
            onCancel={() => setIsOnboardingStatusModalOpen(false)}
            onConfirm={() => onConfirm(menuStatus?.status)}
            confirmText={
                menuStatus?.status === MenuStatus.FAILED
                    ? 'Try again'
                    : 'Continue'
            }
            showCancelBtn={
                menuStatus?.status === MenuStatus.FAILED ? true : false
            }
            showFooter={
                menuStatus?.status === MenuStatus.SUCCESS ||
                (menuStatus?.status === MenuStatus.FAILED &&
                    onboardingStatus === 'MENU_BLOCK')
            }
            showLoadingIndicator={
                menuStatus?.status === MenuStatus.PENDING ? true : false
            }
            gap={menuStatus?.status === MenuStatus.FAILED ? '1.875rem' : '0rem'}
        >
            {content(menuStatus?.status)}
        </ReusableModal>
    );
}
