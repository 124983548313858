import React from 'react';
import { Button, Icon, Text } from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';

import { getConfigVar } from 'features/common';
import { useAppDispatch, useAppSelector } from 'api/store';
import { getSignupUrl } from '../../MerchantOnboarding.slice';
import { HTTP_STATUS_CODES } from 'utils/constants';
import Styles from './Location.module.scss';

export interface ILocationError {
    locationErrorMessage: { statusCode: number | undefined; message: string };
}
export function LocationError({ locationErrorMessage }: ILocationError) {
    const dispatch = useAppDispatch();
    const { businessLocationId } = useAppSelector(
        (state) => state.merchantOnboarding,
    );
    const onClickSignInLink = () => {
        window.location.href = getConfigVar('REACT_APP_DOORDASH_SIGNIN_URL');
    };

    const onClickSignUpLink = async () => {
        if (businessLocationId) {
            const res = await dispatch(getSignupUrl({ businessLocationId }));

            if (res && isFulfilled(res)) {
                if (res.payload.status === 'success') {
                    window.location.href = res.payload.data.signup_url;
                }
            }
        }
    };

    return (
        <>
            <span
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.25rem',
                }}
                data-testid="error-message-container"
            >
                <Text color="#BA2C30" data-testid="location-error-message">
                    {locationErrorMessage.message}
                </Text>
            </span>
            {locationErrorMessage.statusCode ===
                HTTP_STATUS_CODES.PRECONDITION_FAILED ||
            locationErrorMessage.statusCode === HTTP_STATUS_CODES.NOT_FOUND ? (
                <div
                    style={{
                        marginTop:
                            locationErrorMessage.statusCode ===
                            HTTP_STATUS_CODES.PRECONDITION_FAILED
                                ? '0.5625rem'
                                : '0',
                    }}
                >
                    {locationErrorMessage.statusCode ===
                        HTTP_STATUS_CODES.NOT_FOUND && (
                        <Button
                            variant="tertiary"
                            className={Styles.Location_linkBtn}
                            onClick={onClickSignUpLink}
                            data-testid="sign-up-link"
                        >
                            <Icon name="LocationIcon" size={24} />
                            <span>Create a DoorDash Store</span>
                        </Button>
                    )}
                    <Button
                        variant="tertiary"
                        className={Styles.Location_linkBtn}
                        onClick={onClickSignInLink}
                        data-testid="sign-in-link"
                    >
                        <Icon name="AccountIcon" size={24} />
                        <span>Use a different DoorDash account</span>
                    </Button>
                </div>
            ) : null}
        </>
    );
}
