// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OnboardingStatus_OnboardingStatus_doorDashMenuLink__\\+Kesv{gap:.5rem;margin:0;margin:initial;margin-top:.875rem;font-weight:500;line-height:inherit;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/components/onboarding_status/OnboardingStatus.module.scss"],"names":[],"mappings":"AACI,4DACI,SAAA,CACA,QAAA,CAAA,cAAA,CACA,kBAAA,CACA,eAAA,CACA,mBAAA,CACA,oBAAA","sourcesContent":[".OnboardingStatus {\n    &_doorDashMenuLink {\n        gap: 0.5rem;\n        margin: unset;\n        margin-top: 0.875rem;\n        font-weight: 500;\n        line-height: unset;\n        text-decoration: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OnboardingStatus_doorDashMenuLink": `OnboardingStatus_OnboardingStatus_doorDashMenuLink__+Kesv`
};
export default ___CSS_LOADER_EXPORT___;
